<template>
<div class="container-fluid px-4">
  <template v-if="loading">
    <app-loading></app-loading>
  </template>
  <template v-else>
    <div class="row">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-8">
            <h2 class="h2 e-text-red">Summary of Managing Vessels</h2>
          </div>
        </div>

        <div class="row col-12 p-0 m-0">
          <table class="table text-center table-sm small e-tbl">
            <thead class="bg-white">
            <tr>
              <th class="align-middle" scope="col">VESSEL TYPE</th>
              <template v-for="officeType in mngmntOfficeKeys">
                <th class="text-uppercase align-middle" scope="col">{{ officeType.name }}</th>
              </template>
              <th style="background-color: #d3313c !important" class="text-white">TOTAL</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="record in vesselManagement.vesselRecordByTypeAndOffice">
              <tr>
                <td class="text-uppercase align-middle">
                  {{ record.name }}
                </td>

                <template v-for="officeType in record.vessel_office_type">
                  <td class="text-uppercase align-middle">
                    {{ officeType.count }}
                  </td>
                </template>

                <td class="e-bg-red text-white">
                  {{ record.total_count }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: $route.name, params : {
              report_type : 'flag'
            }}">
                <span style="height: 100%">BY FLAG</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: $route.name, params : {
              report_type : 'age'
            }}">
                <span>BY AGE</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
      <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
    </div>
    <div class="row" v-if="$route.params.report_type === 'flag' && vesselRecordByFlagSeries.length > 0">
      <div class="col-12">
        <div class="card shadow-sm rounded mb-3 mt-3">
          <div class="card-body">
            <div>
              <h5>BY FLAG</h5>
              <VesselRecordPieChart
                :key="$route.params.report_type + '_' + vesselRecordByFlagSeries.length"
                :series="vesselRecordByFlagSeries"
                :labels="vesselRecordByFlagLabels.map(str => str.toUpperCase())"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="$route.params.report_type === 'age' && vesselRecordByAgeSeries.length > 0">
      <div class="col-12">
        <div class="card shadow-sm rounded mb-3 mt-3">
          <div class="card-body">
            <h5>BY AGE</h5>
            <VesselRecordPieChart
              :key="$route.params.report_type + '_' + vesselRecordByAgeSeries.length"
              :series="vesselRecordByAgeSeries"
              :labels="vesselRecordByAgeLabels.map(str => str.toUpperCase())"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
</div>
</template>

<script>

import {permissionMixin} from "@/mixins/permissionMixins";
import {mapActions, mapGetters} from "vuex";
import VesselRecordPieChart from "@/components/dashboard/number-of-management/VesselRecordPieChart.vue";
import AppLoading from "@/components/elements/AppLoading.vue";


export default {
  name: 'NumberOfManagement',
  mixins:[permissionMixin],
  data(){
    return {
      loading : true
    }
  },
  components: {
    AppLoading,
    VesselRecordPieChart
  },
  methods:{
    ...mapActions([
      'getDashboardVesselManagement',
      'getMngmtOfficeKeys',
    ])
  },
  async created() {
    await this.getMngmtOfficeKeys();
    await this.getDashboardVesselManagement();

    this.loading = false;
  },
  computed:{
    ...mapGetters([
      'mngmntOfficeKeys',
      'vesselManagement',
    ]),
    vesselRecordByFlagSeries() {
      return this.vesselManagement?.vesselRecordByFlag?.map(record => record.vessel_count);
    },
    vesselRecordByFlagLabels() {
      return this.vesselManagement?.vesselRecordByFlag?.map(record => record.name);
    },
    vesselRecordByAgeSeries() {
      return this.vesselManagement?.vesselRecordByAge?.map(record => record.count);
    },
    vesselRecordByAgeLabels() {
      return this.vesselManagement?.vesselRecordByAge?.map(record => record.name);
    },
  },
}
</script>

<style scoped >
#ModelNav {
  padding: 0;
  li > a {
    padding: .5rem 2rem;
    color: #435464;
    height: 100%;
    text-align: center;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
  .nav-link span {
    font-size: 14px;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  font-weight: bold;

  * {
    color: #d3313c;
  }
}
</style>

